import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import { getServerSideClients } from '@dx-ui/framework-react-query';
import { GLOBAL_NAMESPACES, HotelPage } from '../../../../helpers/constants';
import { serverSideGetHotelDiningPageQuery } from '@dx-ui/queries-dx-property-ui';
import { useRouter } from 'next/router';
import DiningGrid from '../../../../components/dining-grid/DiningGrid';
import nextI18nextConfig from '../../../../next-i18next.config';
import HotelLayout from '../../../../components/layout/HotelLayout';
import type { StandardSchema } from '../../../../components/head/page-schemas';
import { CustomHead } from '../../../../components/head';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { useStaticUrls } from '../../../../hooks/useStaticUrls';
import { isRenovatedProperty } from '../../../../helpers/utils/server/toggles';
import { serverSideLayoutAndRedirects } from '../../../../helpers/utils/server/redirect';
import { getDehydratedQueryState } from '../../../../helpers/utils/server/getDehydratedQueryState';
import {
  getCtyhocnFromSlug,
  getHotelsRouteParams,
  getBrandSvg,
} from '../../../../helpers/utils/client';
import { getLayoutData } from '../../../../helpers/utils/server/getLayoutData';

const pageName = HotelPage.DINING;
const namespaces = [...GLOBAL_NAMESPACES, 'half-and-half', 'osc-accordion', pageName];

const DiningPage = ({
  restaurantOverview,
  layout,
  isRecentlyRenovated,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  const { baseAppUrl } = useStaticUrls();

  const { ctyhocn } = getHotelsRouteParams(router);

  const standardSchema: StandardSchema = {
    address: layout?.address,
    coordinates: layout?.coordinates,
    image: layout?.image || '',
    openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su',
    schemaType: 'Hotel',
    description: layout?.seoMetaData.desc,
    name: layout?.hotelName,
    url: baseAppUrl,
    telephone: layout?.phone || '',
  };

  return (
    <HotelLayout
      layout={layout}
      page={pageName}
      ctyhocn={ctyhocn}
      isRecentlyRenovated={isRecentlyRenovated}
    >
      <CustomHead
        brandLogoImageSrc={getBrandSvg(layout?.headerData.brandCode)}
        schema={standardSchema}
        metaDescription={layout?.seoMetaData.desc}
        metaKeywords={layout?.seoMetaData.keywords}
        page={pageName}
        pageTitle={layout?.seoMetaData.title}
        brandCode={layout?.headerData.brandCode}
        shouldUseDefaultBrandFavicon={layout?.headerData?.suppressLogo}
      />
      <DiningGrid restaurantOverview={restaurantOverview} />
    </HotelLayout>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { res, params, locale } = context;
  const ctyhocn = getCtyhocnFromSlug(params?.hotelSlug);

  const envVars = getQueryProviderProps();
  const { queryClient } = getServerSideClients({
    ...envVars,
    appName: 'dx-property-ui',
    response: res,
  });

  const {
    globalConfig,
    layoutResult,
    mainNavLinks,
    resEnabled,
    notFound,
    oneLinkProps,
    originalLocale,
    featureConfigs,
    isBrandRefresh,
  } = await serverSideLayoutAndRedirects({
    queryClient,
    ctyhocn,
    pageName,
    hotelSlug: params?.hotelSlug as string,
    context,
  }).catch((e) => {
    console.log('Error in serverSideLayoutAndRedirects', e); // eslint-disable-line no-console

    return {
      globalConfig: null,
      layoutResult: null,
      mainNavLinks: null,
      resEnabled: null,
      notFound: true,
      oneLinkProps: null,
      originalLocale: null,
      featureToggles: null,
      featureConfigs: null,
      isBrandRefresh: false,
    };
  });

  if (notFound) {
    return { notFound: true };
  }

  const layout = getLayoutData({
    ctyhocn,
    layoutResult,
    resEnabled,
    mainNavLinks,
    locale: originalLocale || locale,
  });

  const diningResult = await serverSideGetHotelDiningPageQuery(queryClient, {
    ctyhocn,
  }).catch((e) => {
    console.log('Error in serverSideGetHotelDiningPageQuery', e); // eslint-disable-line no-console
    return null;
  });

  if (!diningResult) {
    return { notFound: true };
  }

  const isRecentlyRenovated = isRenovatedProperty(ctyhocn, featureConfigs);
  const brandCode = layoutResult?.hotelPageTemplate?.hotel?.brandCode || '';

  return {
    props: {
      globalConfig,
      isBrandRefresh,
      restaurantOverview: diningResult?.hotel?.restaurantOverview,
      layout,
      isRecentlyRenovated,
      themeColors: layout.theme,
      ...(await serverSideTranslations('en', namespaces, nextI18nextConfig)),
      ...oneLinkProps,
      dehydratedQueryState: getDehydratedQueryState(queryClient),
      metrics: {
        trackingData: {
          pageData: {
            brandCode,
            pageName,
            ctyhocn,
          },
        },
      },
    },
  };
}

export default DiningPage;
